import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout/Layout'
import Blocks from '@/lib/blocks/Blocks'
import MetaInfo from '@/layout/MetaInfo'

const HomePg = ({ data: { pg } }) => (
	<Layout>
		<Blocks blocks={pg.blocks} />
	</Layout>
)

export default HomePg

export const Head = MetaInfo

export const query = graphql`query Homepage {
	pg: sanityHomepage {
		blocks {
			...blogCarousel
			...cardSection
			...eventList
			...footerCtaSection
			...heroMain
			...impactSection
			...testimonialSection
			...textMediaSection
		}
		metaInfo { ...metaInfo }
	}
}`
